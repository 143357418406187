<template>
  <header class="header" @click="closeMouth">
    <div class="header__top-section">
      <div class="header__left-section">
        <button class="header__menu-icon" @click="openSidebar">
          <svg :style="{ width: '24px', height: '24px' }">
            <path :d="mdiMenu"/>
          </svg>
        </button>
        <h1 class="header__main-headline">Tobias-Finn El Lebeidy</h1>
      </div>
      <div class="header__right-section">
        <HeaderNavigation/>
      </div>
    </div>
    <hr class="header__divider"/>
    <div class="header__bottom-section">
      <h2 class="header__sub-headline">Web Development</h2>
    </div>
  </header>
</template>
<script setup>
import HeaderNavigation from '@/components/HeaderNavigation'
import { mdiMenu } from '@mdi/js'
import { useStore } from 'vuex'
import { ref } from 'vue'

const store = useStore()
const closeMouth = () => {
  store.dispatch('avatar/setAvatarClosed')
}

const openSidebar = () => {
  store.dispatch('sidebar/setOpen')
  isSidebarOpen.value = store.state.sidebar.isOpen
}

const isSidebarOpen = ref(store.state.sidebar.isOpen)

</script>

<style lang="scss" scoped>
.header {
  padding: 10px;
  @include font-style('yanone', 'normal', 'normal');
  background-color: transparent;
  z-index: 3;

  &__main-headline {
    @include font-style('inherit', 'normal', 'h1');
    font-size: 2rem;
    min-width: 200px;
  }

  &__top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  &__left-section {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__menu-icon {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: all;
    fill: $background;
    background-color: $font-color;;
  }

  &__right-section {
    display: flex;
    gap: 1rem;
  }

  &__divider {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #ccc;
  }

  &__bottom-section {
    display: flex;
    justify-content: flex-end;
  }

  &__sub-headline {
    @include font-style('yanone', 'normal', 'h2');
    //  font-size: 1.8rem;
  }

  @media (min-width: 980px) {
    & {
      margin-left: 250px;
    }

    &__menu-icon {
      display: none;
    }
  }
}
</style>
