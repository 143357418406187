export const education = [
  {
    period: '07/2021 - 08/2021',
    position: 'PHP Developer Weiterbildung',
    institute: 'bei alfatraining',
    info: '<p>8-wöchige Forbildung in Vollzeit. Die Inhalte <a href=\'../zeugnisse/Inhalte_PHP.pdf\' download>hier</a>, weitere Informationen auf der <a href=\'https://www.alfatraining.de/gefoerderte-weiterbildung/hamburg/stadt/hamburg/kurs/php-developer/N/0/0/kombi/php-developer/\' target=\'_blank\'>Homepage von alfatraining</a></p>'

  },
  {
    period: '08/2019 - 10/2019',
    position: 'Web Development Weiterbildung',
    institute: 'bei neuefische - School and Pool for Digital Talent',
    info: '<p>Die Inhalte und den Stundenumfang des Bootcamps finden Sie <a href=\'../zeugnisse/nf-contents.pdf\' download>hier</a>, weitere Informationen auf der <a href=\'https://www.neuefische.de\' target=\'_blank\'>Homepage von neuefische</a></p>'

  },
  {
    period: '09/2018 - 08/2019',
    position: 'Studium: Angewandte Informatik,',
    institute: 'Hochschule für angewandte Wissenschaften Hamburg',
    info: '<p>Opt-out <br> angestrebter Abschluss: Bachelor of Science <br> <br> <ul>besuchte Module: <li>Programmiertechnik</li><li>Grundlagen der Informatik</li><li>BWL 1</li> <li>Mathematische Grundlagen</li></ul></p>'
  },
  {
    period: '10/2012 - 09/2014',
    position: 'Studium: Linguistik,',
    institute: 'Humboldt-Universität zu Berlin',
    info: '<p>opt-out <br>angestrebter Abschluss: M.A. <br>Schwerpunkte: Psycholinguistik und Korpuslinguistik</p>'
  },
  {
    period: '10/2011 - 03/2012',
    position: 'Aufenthalt in Kairo/Ägypten,',
    institute: 'zur Verbesserung von Arabischkenntnissen',
    info: '<p>u.a. Besuch der Kurse Egyptian Colloquial Arabic (ECA) 3 und 4 der Kalimat-Sprachschule Kairo. Nähere Infos auf der <a href=\'https://kalimategypt.com/wp-content/uploads/2019/05/Egyptian_Colloquial_ArabicECA-Syllabus.pdf\' target=\'_blank\'>Homepage der Sprachschule</a></p>'
  },
  {
    period: '10/2007 - 09/2011',
    position: 'Studium: Gebärdensprachen und Deutsche Sprache und Literatur,',
    institute: 'Universität Hamburg',
    info: '<p>Sprachwissenschaftlicher Studiengang; <br> Schwerpunkte: Mehrsprachigkeit und Varietätenlinguistik; <br>Abschluss: Bachelor of Arts; Note: gut (2,1) <br>Die Studieninhalte finden Sie auf der <a href=\'https://www.slm.uni-hamburg.de/studium/studiengaenge/ba-studiengaenge/gebaerdensprachen.html\' target=\'_blank\'>Homepage der Universität Hamburg</a></p>'

  },
  {
    period: '08/1997 - 07/2006',
    position: 'Abitur, ',
    institute: 'Gymnasium Lohbrügge, Hamburg',
    info: '<p>Leistungsfächer: Deutsch und Englisch; <br>Abschlussnote: gut (2,1).</p>'
  }
]

export const experience = [
  {
    period: 'seit 10/2022',
    position: 'Intermediate Software Engineer (fullstack)',
    institute: ' bei web care LBJ GmbH (pflege.de)',
    info: '<p>Vollzeit, Entwicklung und Pflege von Services auf pflege.de mit Laravel, Frontendentwicklung mit Vue.js</p>'
  },
  {
    period: '10/2021 - 09/2022',
    position: 'Junior Software Engineer (fullstack)',
    institute: ' bei web care LBJ GmbH (pflege.de)',
    info: '<p>Vollzeit, Entwicklung und Pflege von Services auf pflege.de mit Laravel, Frontendentwicklung mit Vue.js</p>'
  },
  {
    period: '03/2021 - 09/2021',
    position: 'Mitarbeit als Entwickler',
    institute: ' bei ovolution',
    info: '<p>Teilzeit, vorwiegend Frontend mit Vue.js. Weitere Informationen finden Sie auf der <a href="http://ovolution.rocks/" target="_blank">Homepage von ovolution</a></p>'
  },
  {
    period: '03/2020 - 02/2021',
    position: 'Junior Web Developer im Team IT-Solutions',
    institute: ' bei DREI-D Direktwerbung GmbH & Co. KG',
    info: '<p>Vollzeit, Einarbeitung in Laravel und Vue.js; Programmierung von Landingpages sowie Mitarbeit an Webshops</p>'
  },
  {
    period: '07/2016 - 08/2019',
    position: 'studentische Hilfskraft (20h/Woche)',
    institute: 'beim International Office des Universitätsklinikums Hamburg-Eppendorf - Dolmetscherdienst',
    info: '<p>Terminvermittlung, Rechnungsbearbeitung, Dolmetscherakquise, Verwaltungstätigkeiten. Wir waren Ansprechpartner für sämtliche Anliegen der Dolmetschenden im und um den Dolmetschprozess am UKE <br /> <br /> <a href="../zeugnisse/ellebeidy_zeugnis_uke.pdf" download>Arbeitszeugnis als PDF herunterladen</a></p>'
  },
  {
    period: '03/2010 - 08/2019',
    position: 'freiberuflich Korrektorat und Lektorat',
    institute: '',
    info: '<p>Korrektur und Lektorat von vorwiegend studentischen Haus-, Bachelor-, Master- und Magisterarbeiten sowie Konzepten zur Geschäftsgründung. Außerdem Kodierung von Multiple-Choice-Tests, Korrektur von studentischen Aufsätzen sowie Transkription von Audio- und Videomaterial</p>'
  },
  {
    period: '10/2008 - 07/2011',
    position: 'studierende Aushilfskraft',
    institute: 'Universität Hamburg, SFB Mehrsprachigkeit 538',
    info: '<p>Transkription, grammatische Annotation, Literaturrecherche und Verwaltungsaufgaben. Mehr Informationen zu dem Projekt finden Sie auf der <a href=\'https://www.uni-hamburg.de/sfb538/forschungsprogramm.html\' target=\'_blank\'>Homepage der Uni Hamburg</a> <br /> <br /> <a href="../zeugnisse/ellebeidy_zeugnis_sfb.pdf" download>Arbeitszeugnis als PDF herunterladen</a></p>'

  },
  {
    period: '10/2010 - 03/2011',
    position: 'Praktikum,',
    institute: 'Universität Hamburg Institut für Germanistik 1: DFG-Projekt „Sprachvariation in Norddeutschland“ (SiN).',
    info: '<p>Transkriptbereinigung mit Exmaralda (XML-basiert). Mehr Informationen zu dem Projekt finden Sie auf der <a href=\'https://corpora.uni-hamburg.de/sin/startseite.html\' target=\'_blank\'>Homepage der Uni Hamburg</a> <br /> <br /> <a href="../zeugnisse/ellebeidy_zeugnis_sin.pdf" download>Praktikumszeugnis als PDF herunterladen</a></p>'

  }
]

export const workData = [
  {
    date: '12/2022',
    title: 'Zwischenzeugnis web care LBJ GmbH',
    path: '../files/zeugnisse/ellebeidy_zwischenzeugnis_pflege.pdf'
  },
  {
    date: '03/2021',
    title: 'Arbeitszeugnis Drei-D Direktwerbung GmbH',
    path: '../files/zeugnisse/ellebeidy_zeugnis_3D.pdf'
  }
]

export const educationData = [
  {
    date: '08/2021',
    title: 'Zertifikat PHP Developer',
    path: 'href="../files/zeugnisse/ellebeidy_Zertifikat_PHP.pdf'
  },
  {
    date: '10/2019',
    title: 'neuefische Zertifikat',
    path: '../files/zeugnisse/ellebeidy_Zertifikat_neuefische.pdf'
  },
  {
    date: '',
    title: 'neuefische Ausbildungsinhalte',
    path: '../files/zeugnisse/nf-contents.pdf'
  }
]

export const otherWorkData = [
  {
    date: '10/2017',
    title: 'Zwischenzeugnis International Office des UKE',
    path: '../files/zeugnisse/ellebeidy_zeugnis_uke.pdf'
  },
  {
    date: '07/2011',
    title: 'Arbeitszeugnis SFB Mehrsprachigkeit',
    path: '../files/zeugnisse/ellebeidy_zeugnis_sfb.pdf'
  },
  {
    date: '03/2011',
    title: 'Praktikumszeugnis Sprachvariation in Norddeutschland',
    path: '../files/zeugnisse/ellebeidy_zeugnis_sin.pdf'
  }
]
