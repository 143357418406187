<template>
  <div class="skill-box">
    <div v-for="(item, index) in props.items" :key="index" class="skill-box__item">
      <img v-if="item.filename"
           :key="item.name"
           :alt="item.text"
           :src="require(`@/assets/logos/${item.filename}`)"
           class="skill"
           @click="setTooltipText(item.info)"
      />
      <p v-else
         :style="{
            color: item.color,
          }"
         @click="setTooltipText(item.info)"
      >
        {{ item.text }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, defineProps } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => []
  },
  type: {
    type: String,
    default: 'WordItem'
  }
})

defineComponent({
  name: 'BorderedBox'
})

const store = useStore()
const setTooltipText = (content) => store.dispatch('avatar/setTooltipText', content)
</script>

<style lang="scss" scoped>
.skill-box {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: #a4e2e2;
  border: 2px solid $font-color;
  justify-content: space-evenly;
  align-content: space-between;

  &__item {
    margin: 20px;
    max-height: 60px;
    cursor: pointer;
    z-index: 3;
    font-weight: normal;
  }
}

.skill {
  max-height: 60px;
}

</style>
