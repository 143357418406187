import { mdiAt, mdiPhone } from '@mdi/js'

export default {
  namespaced: true,
  state: {
    isOpen: false,
    tooltipText: '<p>Default tooltip text</p>'
  },
  actions: {
    toggleAvatarState ({ state }) {
      state.isOpen = !state.isOpen
    },
    setAvatarOpen ({ state }) {
      state.isOpen = true
    },
    setAvatarClosed ({ state }) {
      state.isOpen = false
    },
    setTooltipText ({ state }, text) {
      const emailCharsArray = [105, 110, 102, 111, 64, 116, 101, 108, 108, 102, 105, 110, 110, 46, 100, 101]
      let encryptedEmail = '' // variable to contain encrypted email
      for (let i = 0; i < emailCharsArray.length; i++) {
        encryptedEmail += String.fromCharCode(emailCharsArray[i])
      }

      state.tooltipText = text === ''
        ? '<div class="tooltip__contact-area"><p>Mehr Informationen?</p><div class="tooltip__contact-area--icon-wrapper"><a href="mailto:' + encryptedEmail + '?subject=Kontakt via Homepage" class="contact-icon-link"><svg><path d="' + mdiAt + '" aria-label="email schreiben"/></svg></a> <a href="tel:017697668901" class="contact-icon-link"  aria-label="anrufen"><svg><path d="' + mdiPhone + '" /></a></div></div>'
        : text

      state.isOpen = true
    }
  }
}
