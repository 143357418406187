<template>
  <div :class="['sidebar', {'sidebar--open': props.isOpen }]">
    <div class="sidebar__content">
      <router-link
        class="sidebar__link"
        :to="{ name: 'skills' }"
        active-class="sidebar__link--active"
        @click="handleClick"
      >Kenntnisse
      </router-link
      >
      <router-link
        class="sidebar__link"
        :to="{ name: 'softskills' }"
        active-class="sidebar__link--active"
        @click="handleClick"
      >Softskills
      </router-link
      >
      <router-link
        class="sidebar__link"
        :to="{ name: 'languages' }"
        active-class="sidebar__link--active"
        @click="handleClick"
      >Sprachen
      </router-link
      >
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
})
const store = useStore()
const closeSidebar = () => {
  store.dispatch('sidebar/setClosed')
}
const closeMouth = () => {
  store.dispatch('avatar/setAvatarClosed')
}

const handleClick = () => {
  closeMouth()
  closeSidebar()
}

onMounted(() => {
  store.dispatch('avatar/setTooltipText',
    `<p>Guten Tag! <br />
Sie können auf dieser Homepage Objekte wie Kenntnisse oder im Lebenslauf angegebene Tätigkeiten anklicken und ich gebe Ihnen mehr Informationen dazu.`)
})
</script>

<style lang="scss" scoped>
.sidebar {
  position: absolute;
  flex: 0 0 auto;
  min-height: 100vh;
  width: 20%;
  max-width: 250px;
  min-width: 130px;
  background-color: $navbar-background;
  transform: translateX(-100%);
  transition: transform 0.3s ease;

  &--open {
    transform: translateX(0);
    z-index: 5
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 15%;
    margin-top: 90%;
  }

  &__link {
    margin: 10px 0;
    color: white;
    transition: 0.3s;
    @include font-style('titillium', 'normal', 'normal');

    &--active, :hover {
      font-weight: bolder;
    }
  }

  @media (min-width: 576px) {
    & {
      min-height: calc(100vh + 2rem);
    }
  }
}
</style>
