<template>
  <div id="app">

    <SideNavigation v-if="isAppStarted" :isOpen="isDesktopView || isSidebarOpen"/>
    <OverlayComponent v-if="isSidebarOpen &&!isDesktopView"
                      @click="setSidebarClosed"/>
    <OverlayComponent v-if="isMouthOpen" @click="setMouthClosed" opacity="0"/>
    <Header/>
    <main>
      <router-view class="router-view" v-if="isAppStarted"/>
      <password-input class="input-view" v-else-if="!isLoading"/>
      <div v-else>
        Loading ... checking login
      </div>
    </main>
    <Avatar/>
  </div>
</template>

<script setup>

import Header from '@/components/TheHeader.vue'
import SideNavigation from '@/components/SideNavigation.vue'
import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import Avatar from '@/components/AvatarHead.vue'
import OverlayComponent from '@/components/OverlayComponent.vue'
import PasswordInput from '@/views/PasswordInput.vue'

const store = useStore()

const isDesktopView = ref(window.innerWidth >= 980)
const isSidebarOpen = ref(store.state.sidebar.isOpen)
const isAppStarted = ref(!!store.state.isAppStarted)
const isLoading = ref(true)

const isMouthOpen = computed(() => store.state.avatar.isOpen)
const setSidebarOpen = () => store.dispatch('sidebar/setOpen')
const setMouthClosed = () => store.dispatch('avatar/setAvatarClosed')
const handleResize = () => {
  isDesktopView.value = window.innerWidth >= 980
  if (!isDesktopView.value) {
    setSidebarClosed()
  } else {
    setSidebarOpen()
  }
}

const setSidebarClosed = () => {
  if (isSidebarOpen.value && !isDesktopView.value) {
    store.dispatch('sidebar/setClosed')
  }
}

watch(() => store.state.sidebar.isOpen, (newVal) => {
  isSidebarOpen.value = newVal
})
watch(() => store.state.avatar.isOpen, (newVal) => {
  isMouthOpen.value = newVal
})
watch(() => store.state.auth.isAppStarted, (newVal) => {
  isAppStarted.value = newVal
})

const checkSession = async () => {
  await store.dispatch('auth/checkSession')
  isLoading.value = false
}

onMounted(async () => {
  window.addEventListener('resize', handleResize)
  document.title = 'Tobias-Finn El Lebeidy'
  await checkSession()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

</script>

<style lang="scss" scoped>

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  color: $font-color;
  background-color: $background;
  @include font-style;
}

main {
  text-align: justify;
  min-height: calc(100vh - 130px);
  padding: 0 20px;

  @media (min-width: 980px) {
    & {
      margin-left: 260px;
    }
  }

  @media (max-width: 576px) {
    margin-top: 30px;
  }
}

.router-view {
  @media (min-width: 576px) {
    & {
      margin-top: 2rem;
    }
  }
}

.input-view {
  @media (min-width: 980px) {
    & {
      margin-right: 250px;
    }
  }
}

</style>
