<template>
  <div class="default-layout">
    <div class="default-layout--left">
      <slot name="left"></slot>
    </div>
    <CardComponent class="default-layout__text" :textContent="props.textContent"/>
  </div>
</template>

<script setup>
import CardComponent from '@/components/CardComponent.vue'
import { defineComponent, defineProps } from 'vue'

defineComponent({
  name: 'HomeView'
})

const props = defineProps({
  textContent: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 40px;

  .default-layout--left {
    display: flex;
    justify-content: center;
  }

  &__image {
    max-height: 400px;
    border-radius: 50%;
    max-width: 50%;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;

    .default-layout--left {
      width: 50%;
      max-width: 600px;
    }

    &__image {
      max-height: 800px;
      border-radius: 50%;
    }

    &__text {
      width: 50%;
    }
  }
}
</style>
