<template>
  <div class="avatar-wrapper">
    <div class="head" @click="toggleMouth">
      <div class="hair"></div>
      <div class="hair__side hair__side--left"></div>
      <div class="hair__side hair__side--right"></div>
      <div class="eye eye--left">
        <div>
          <div class="glasses">
            <div class="columella"></div>
          </div>
        </div>
      </div>
      <div class="eye eye--right">
        <div>
          <div class="glasses"></div>
        </div>
      </div>
      <div class="nose">
        <div class="nose__nostrils"></div>
      </div>
      <div class="mouth" :class="{ 'mouth--open': isMouthOpen }">
        <div class="beard"></div>
      </div>
    </div>
    <Tooltip v-if="isMouthOpen"/>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import Tooltip from '@/components/AvatarTooltip'
import { ref, watch } from 'vue'

const store = useStore()
const isMouthOpen = ref(store.state.avatar.isOpen)

const toggleMouth = () => store.dispatch('avatar/toggleAvatarState')

watch(
  () => store.state.avatar.isOpen, (newValue) => {
    isMouthOpen.value = newValue
  })

</script>
<style scoped lang="scss">
.avatar-wrapper {
  position: fixed;

  bottom: 10px;
  z-index: 5;

  @media (max-width: 980px) {
    right: 20px;
  }

  @media (min-width: 980px) {
    left: 250px;
  }

}

.head {
  cursor: pointer;
  height: 96px;
  width: 70px;
  background-color: peachpuff;
  border-bottom-color: transparent;
  border-radius: 50px 50px 100% 100%;
}

.hair {
  position: absolute;
  top: -1px;
  height: 100px;
  width: 70px;
  border-top: 4px solid $haircolor;
  border-radius: 30%;

  &__side {
    position: absolute;
    top: 10px;
    height: 40px;
    width: 8px;
    background-color: $haircolor;

    &--left {
      left: -3px;
      border-top-left-radius: 70px;
      border-bottom-right-radius: 50px;
    }

    &--right {
      right: -3px;
      border-top-right-radius: 70px;
      border-bottom-left-radius: 50px;
    }
  }
}

.eye {
  position: absolute;
  top: 40%;
  height: 10px;
  width: 18px;
  background-color: white;
  border-radius: 50%;

  &--left {
    left: 8px;
  }

  &--right {
    right: 13px;
  }

  > div {
    position: absolute;
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    height: 8px;
    width: 8px;
    background-color: #412c20;
    border-radius: 50%;
  }
}

.mouth {
  position: absolute;
  bottom: 16px;
  left: calc(50% - 10px);
  height: 0;
  width: 20px;
  border: 3px solid #dd8480;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &--soso {
    border-radius: 0;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  &--open {
    height: 2px;
    border: 3px solid #dd8480;
    border-radius: 50%;
  }
}

.glasses {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 10px);
  height: 18px;
  width: 22px;
  background-color: transparent;
  border: 2px solid black;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.columella {
  position: absolute;
  top: calc(30%);
  right: -20px;
  height: 2px;
  width: 20px;
  background-color: black;
}

.beard {
  position: absolute;
  top: -10px;
  left: -10px;
  height: 30px;
  width: 36px;
  background-color: transparent;
  border: 4px solid $haircolor;
  border-bottom-width: 9px;
  border-radius: 5px;
  border-bottom-left-radius: 70%;
  border-bottom-right-radius: 70%;
}

.nose {
  position: absolute;
  top: 46px;
  left: calc(50% - 5px);
  height: 15px;
  width: 8px;
  background-color: $nose-color;

  &__nostrils {
    position: absolute;
    left: calc(50% - 7px);
    bottom: -5px;
    height: 10px;
    width: 14px;
    background-color: $nose-color;
    border-radius: 50%;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
  }
}

@keyframes grow {
  50% {
    transform: scale(1.1);
  }
}

@-webkit-keyframes grow {
  50% {
    transform: scale(1.1);
  }
}
</style>
