import { createStore } from 'vuex'
import avatarModule from '@/store/modules/avatar'
import authModule from '@/store/modules/auth'
import sidebarModule from '@/store/modules/sidebar'

export default createStore({
  modules: {
    avatar: avatarModule,
    auth: authModule,
    sidebar: sidebarModule
  }
})
