<template>
  <div class="downloads-view">
    <table v-for="(data, index) in arrays" :key="index" class="downloads-view__table">
      <tbody>
      <tr>
        <td class="downloads-view__cell downloads-view__cell--fixed-width"></td>
        <td class="downloads-view__cell downloads-view__cell--flexible-width">
          <div class="downloads-view__header">{{ data.title }}</div>
        </td>
        <td class="downloads-view__cell downloads-view__cell--fixed-width">
          <div class="downloads-view__header downloads-view__header--hidden">Download</div>
        </td>
      </tr>
      <tr v-for="(item, index) in data.items" :key="index">
        <td class="downloads-view__cell downloads-view__cell--fixed-width">
          <div>{{ item.date }}</div>
        </td>
        <td class="downloads-view__cell downloads-view__cell--flexible-width">
          <div>{{ item.title }}</div>
        </td>
        <td class="downloads-view__cell downloads-view__cell--fixed-width">
          <div>
            <a :href="item.path" download>PDF</a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <CardComponent :text-content="{ content: downloadsText }"/>
  </div>
</template>

<script setup>
import { computed, defineComponent, ref } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import { educationData, otherWorkData, workData } from '@/assets/tableContents/cv_data'

defineComponent({
  name: 'DownloadsView'
})

const arrays = [
  {
    title: 'Arbeitszeugnisse Web Development',
    items: workData
  },
  {
    title: 'Zertifikate',
    items: educationData
  },
  {
    title: 'Arbeitszeugnisse anderer Tätigkeiten',
    items: otherWorkData
  }
]

const email = computed(() => {
  const emailCharsArray = [105, 110, 102, 111, 64, 116, 101, 108, 108, 102, 105, 110, 110, 46, 100, 101]
  const string = ref('')
  for (let i = 0; i < emailCharsArray.length; i++) {
    string.value += String.fromCharCode(emailCharsArray[i])
  }
  return string.value
})

const downloadsText = `Sollten Sie Bedarf an weiteren Dokumenten oder Zeugnissen haben, schreiben Sie mir eine <a href="mailto:${email.value}?subject=Bitte um Dokumente"> E-Mail</a>.`
</script>

<style lang="scss" scoped>
.downloads-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;

  &__header {
    @include font-style('inherit', 'bolder', 'inherit');

    &--hidden {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__table {
    width: 100%;
    line-height: 2em;
    border-collapse: collapse;
    table-layout: fixed;

    @media (min-width: 768px) {
      width: 750px;
    }
    @media (max-width: 768px) {
      max-width: 90%;

      > * > tr {
        display: flex;
        flex-direction: column;
        border-bottom: $font-color dotted 1px;
      }

    }
  }

  &__cell {
    white-space: nowrap;

    &--fixed-width {
      width: 100px;
    }

    &--flexible-width {
      width: auto;
      @media (max-width: 768px) {
        max-width: 80%;
        white-space: normal;
      }
    }
  }

}

</style>
