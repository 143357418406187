export const knowledgeText =
  {
    topic: 'Kenntnisse',
    headline: 'Was ich kann...',
    content: '<p>Mit dem hier angeführten Tech-Stack arbeite ich seit März 2020 regelmäßig. <br /><br />Ich bin derzeit als Fullstack-Entwickler angestellt. Seit Januar 2023 liegt mein Fokus vorwiegend im Backend und auf Dev-Ops. Letztendlich hängt es aber immer an der Arbeitslast: Ich übernehme die Aufgaben, die anstehen, und springe dort ein, wo es benötigt wird.<br /><br /></p>',
    headline2: '...und was ich lernen möchte',
    content2: '<p>Ich lerne sehr gerne dazu. Neben der Vertiefung der hier genannten Kenntnisse, baue ich gern Hintergrundwissen auf und lerne neue Skills. </p>'
  }

export const technologiesText =
  {
    topic: 'verwendete Technologien',
    headline: 'Mein technischer und <br>praktischer Background',
    content: '<p>Mit dem hier angeführten Tech-Stack arbeite ich seit März 2020 alltäglich und regelmäßig. <br /> Mein Einstieg ins Web Development fand über das Frontend statt. Inzwischen habe ich meinen Schwerpunkt im Backend und teilweise auf DevOps (Containerisierung und Deployment). Ich bin als Fullstack Entwickler angestellt und letztendlich hängt es immer an der Arbeitslast: Ich übernehme die Aufgaben, die anstehen, und springe dort ein, wo es benötigt wird.<br /><br />In der Vergangenheit habe ich auch mit anderen Technologien und Programmiersprachen gearbeitet, die ich hier nicht anführe, da ich sie nicht (mehr) aus dem Kopf beherrsche. <br /> Ich bin schnell und gut im Recherchieren und Lernen. Ich habe mich freiberuflich und angestellt stets problemlos in verschiedene Frameworks, Programme und Tools eingefunden. </p>'

  }

export const languagesText =
  {
    topic: 'Sprachkenntnisse',
    headline: 'Sprache verbindet. Im richtigen Leben und auch im virtuellen.',
    content: '<p>Wie viele Menschen, die mehrsprachig aufgewachsen sind, habe ich früh ein metasprachliches Bewusstsein entwickelt. <br />Es fällt mir leicht, sprachliche Strukturen und die Systematik einer Sprache zu erfassen. <br /> Wie in anderen Bereichen gilt leider auch hier: in Phasen, in denen ich eine Sprache nicht aktiv nutze, rückt diese in den Hintergrund. </p>'
  }

export const softSkillsText =
  {
    topic: 'Softskills',
    headline: 'Was mich ausmacht und <br>was ich menschlich einbringe',
    content: '<p>Ich habe eine schnelle Auffassungsgabe, lerne sehr gern und bin geistig flexibel. <br />Im beruflichen und privaten Umfeld werde ich für lösungsorientiertes, ordentliches Arbeiten, meine Teamfähigkeit sowie meine Zuverlässigkeit und Ausdauer geschätzt. Dies zeigt sich auch darin, dass ich teamübergreifend als Sparringpartner hinzugezogen werde. <br /> Ich bin ein offener und empathischer Mensch. Bei Schwierigkeiten bemühe ich mich sach- und zielorientiert um die Entwicklung konstruktiver Strategien. <br /> <br/> Ich bin interessiert an Kultur und Literatur und habe Freude am Kochen und an gutem Essen.</p>'
  }
