import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Downloads from '@/views/Downloads.vue'
import SkillView from '@/views/Skills.vue'
import Contact from '@/views/Contact.vue'
import Bio from '@/views/Bio.vue'
import Fehler from '@/views/404.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: Downloads
  },
  {
    path: '/bio',
    name: 'bio',
    component: Bio
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/knowledge',
    name: 'skills',
    component: SkillView,
    props: { topic: 'Kenntnisse' }
  },
  {
    path: '/tech',
    name: 'technologies',
    component: SkillView,
    props: { topic: 'verwendete Technologien' }
  },
  {
    path: '/softskills',
    name: 'softskills',
    component: SkillView,
    props: { topic: 'Softskills' }
  },
  {
    path: '/languages',
    name: 'languages',
    component: SkillView,
    props: { topic: 'Sprachkenntnisse' }
  },
  {
    path: '/:catchAll(.*)',
    name: '404View',
    component: Fehler
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,

  routes
})

export default router
