export const environment = [
  {
    color: '#bf6184 !important',
    text: 'Postman',
    proficiency: 6,
    info: '<p>REST-Api-Endpunkte teste ich mit Postman ab. Ich habe auch mit anderen API Clients, bspw- Insomnia, erste Erfahrungen gemacht.</p>'
  },
  {
    color: '#bf6184 !important',
    text: 'Node.js',
    proficiency: 6,
    info: ''
  }
]

export const formerTechnology = [
  {
    color: '#5e8587 !important',
    text: 'storybook',
    proficiency: 5,
    info: ''
  },
  {
    color: '#5e8587 !important',
    text: 'TablePlus',
    proficiency: 6,
    info: '<p>Ich nutze derzeit TablePlus oder das PHPStorm-Database-Tool für DB-Management. Ich habe in der Vergangenheit auch mit anderen Tools gearbeitet, bspw. Microsoft SQL Server Management Studio.</p>'
  },
  {
    color: '#5e8587 !important',
    text: 'i18n',
    proficiency: 5,
    info: ''
  },
  {
    color: '#5e8587 !important',
    text: 'MAMP/WAMP',
    proficiency: 6,
    info: '<p>Lokal habe ich mit MAMP und WAMP entwickelt - aber auch und verstärkt mit docker-Containern, damit die lokale Entwicklungsumgebung und die Produktionsumgebung einander möglichst ähnlich sind.</p>'
  },
  {
    color: '#5e8587 !important',
    text: 'React.js',
    proficiency: 4,
    info: "<p>Ich hatte erste Berührungspunkte mit React.js: 2019 in dem neuefische-Bootcamp mit React.js. Später habe ich in einem Projekt einige React.js-Projekte in Vue.js 'umgezogen'.</p>"
  }
]

export const languages = [
  {
    color: '#bf6184 !important',
    text: 'Deutsch',
    proficiency: 10,
    info: '<p>Deutsch ist meine Muttersprache.</p>'
  },
  {
    color: '#e52b1b !important',
    text: 'Englisch',
    proficiency: 8,
    info: '<p>Englisch war die Kommunikationssprache meiner Eltern und damit die in unserer Familie vorwiegend gesprochene Sprache.</p>'
  },
  {
    color: '#826052 !important',
    text: 'Arabisch',
    proficiency: 5,
    info: '<p>ägyptischer Dialekt, vorwiegend mündlich, ~ B1</p>'
  },
  {
    color: '#5e8587 !important',
    text: 'Spanisch',
    proficiency: 5,
    info: '<p>B1</p>'
  },
  {
    color: '#95445c !important',
    text: 'Deutsche Gebärdensprache',
    proficiency: 5,
    info: '<p>Deutsche Gebärdensprache, im Bachelor erworben.</p>'
  }
]

export const softskills = [
  {
    color: '#95445c !important',
    text: 'zuverlässig',
    proficiency: 6,
    info: ''
  },
  {
    color: '#bf6184 !important',
    text: 'vertrauenswürdig',
    proficiency: 6,
    info: ''
  },
  {
    color: '#826052 !important',
    text: 'ausdauernd, gründlich',
    proficiency: 5,
    info: '<p>Ich bleibe auch beim Aufkommen von Problemen hartnäckig. Wenn ich an einer Sache dran bin, möchte ich sie gern gut und richtig zu Ende bringen - <i>wenn schon, denn schon</i>.</p>'
  },
  {
    color: '#95445c !important',
    text: 'interkulturell kompetent',
    proficiency: 7,
    info: '<p>Ich komme aus einer in vielerlei Hinsicht diversen Familie - nicht nur, aber auch bezüglich ihres multikulturellen und multireligiösen Hintergrunds.</p>'
  },
  {
    color: '#bf6184 !important',
    text: 'neugierig',
    proficiency: 5,
    info: '<p>Ich verstehe Dinge gern und gehe ihnen auf den Grund. Das beinhaltet nicht nur, dass ich mich gern in neue Themen einarbeite.</p>'
  },
  {
    color: '#826052 !important',
    text: 'teamfähig',
    proficiency: 5,
    info: ''
  },
  {
    color: '#95445c !important',
    text: 'gradlinig, integer',
    proficiency: 5,
    info: ''
  }
]
