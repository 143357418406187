<template>
  <table class="content-table">
    <tbody>
    <tr
      v-for="(item, index) in props.array"
      :key="index"
      @click="handleTooltip(item.info)"
    >
      <td class="content-table__cell content-table__cell--left">
        {{ item.period }}
      </td>
      <td class="content-table__cell content-table__cell--right">
        {{ item.position }} {{ item.institute }}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script setup>
import { defineProps } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  array: Array
})
const store = useStore()

const handleTooltip = (text) => {
  // store.dispatch('avatar/setAvatarClosed')
  store.dispatch('avatar/setTooltipText', text)
}

</script>

<style lang="scss">

.content-table {
  width: 95%;
  border-collapse: collapse;
  margin: 1rem;
  z-index: 4;

  &__cell {
    padding: 0.75rem 0;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  &__cell--left {
    width: 160px;
  }

  &__cell--right {
    padding-left: 1rem;
  }
}

</style>
