<template>
  <div>
    <MainLayout :textContent="textContent">
      <template #left>
        <img
          alt="Portraitfoto"
          class="responsive-layout__image"
          src="@/assets/tfe.jpg"
        />
      </template>
    </MainLayout>
  </div>
</template>

<script setup>
import MainLayout from '@/components/MainLayout.vue'
import { defineComponent } from 'vue'

defineComponent({
  name: 'HomeView'
})

const textContent = {
  headline: 'Schön, dass Sie sich ein Bild von mir machen möchten!',
  content: '<p>Derzeit bin ich als Fullstack-Entwickler bei der webcare LBJ GmbH (<a class="textlink" href="https://www.pflege.de/" target="_blank">pflege.de</a>) tätig. Seit meinem Quereinstieg ins Web Development im März 2020 arbeite ich als Full Stack Entwickler vorwiegend mit Laravel (7-11), Vue.js (2 + 3), MySQL und SASS.'
}

</script>

<style lang="scss" scoped>
.responsive-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-wrapper {
    width: 100%;
    max-width: 600px;
  }

  &__image {
    max-height: 200px;
    border-radius: 50%;
    max-width: 50%;
    margin-right: 20px;
  }

  &__text {
    margin-top: 20px;
    padding: 0 20px;

    &__headline {
      margin: 0;
    }

    &__copy {
      margin: 10px 0 0;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;

    .image-wrapper {
      width: 50%;
      max-width: 600px;
    }

    &__image {
      max-height: 500px;
      border-radius: 50%;
      margin-right: 20px;
    }

    &__text {
      width: 50%;
      margin-top: 0;
    }
  }
}

</style>
