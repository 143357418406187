export const skillLogos = [
  {
    name: 'vue',
    filename: '512px-Vue.js_Logo_2.svg.png',
    text: 'Vue.js',
    info: '<p>Ich habe im März 2020 begonnen, Vue.js zu lernen, und arbeite seither in allen Projekten damit. Bisher meist mit vuex- oder pinia-store und vuejs/router. Bei einigen Projekten arbeite ich auch mit nuxt (3)</p>'
  },
  {
    name: 'js',
    filename: 'JavaScript-logo.png',
    text: 'Javascript',
    info: '<p>ES6+, seit 2019</p>'
  },
  {
    name: 'css',
    filename: 'CSS3_logo_and_wordmark.svg.png',
    text: 'CSS3',
    info: ''
  },
  {
    name: 'sass',
    filename: 'sass-logo.png',
    text: 'SASS',
    info: '<p>SASS <br />2019 bei neuefische erworben, seither habe ich in allen Projekten, an denen ich beteiligt war, damit gearbeitet.</p>'
  },
  {
    name: 'mySQL',
    filename: 'mysql_logo.png',
    text: 'mySQL',
    info: '<p>Ich habe vorwiegend Erfahrung mit mySQL-Datenbanken, habe auch schon mit MongoDB/NoSQL gearbeitet.</p>'
  },
  {
    name: 'docker',
    filename: 'docker_logo.png',
    text: 'docker',
    info: '<p>Ich habe docker-Container für verschiedene Deployments vorbereitet - bspw. zu wählende Images recherchiert, in der .dockerfile und .docker-compose.yml konfiguriert und die Imagewahl begründet. Hierzu zählten php-, nginx-, apache- und node-Images. Auch die Konfiguration in der nginx.config oder .htaccess zählen an dieser Stelle zu meinen Aufgaben.</p>'
  },
  {
    name: 'PHPUnit',
    filename: 'phpunit_logo.png',
    text: 'PHPUnit',
    info: '<p>In PHP schreibe ich Tests - Feature und Unit - mit PHPUnit.</p>'
  },
  {
    name: 'laravel',
    filename: '1200px-Logo.min.svg.png',
    text: 'Laravel 6 - 11',
    info: '<p>Ich habe im März 2020 begonnen, Laravel zu lernen, und arbeite seither täglich damit.</p>'
  },
  {
    name: 'L5-Swagger',
    filename: 'swagger-docu_logo.png',
    text: 'L5-Swagger',
    info: '<p>Derzeit teste und dokumentiere ich REST-Api-Endpunkte u.a. mit L5-Swagger. Weitere Dokumentation findet außerdem in Readme.md-Dateien oder auf Confluence-Seiten statt.</p>'
  },
  {
    name: 'TeamCity',
    filename: 'teamcity_logo.png',
    text: 'TeamCity',
    info: '<p> Für das Deployment lege ich die build Steps in Teamcity an und nutze AWS CloudFront (CDN), Route 53 (DNS) und Elastic Container Registry.  <br /> <span style=\'font-size: smaller;\'>Copyright des Produktnamens und Logos: © 2023 JetBrains s.r.o. </span> </p>'
  }
]
