<template>
  <div class="pw-view">
    <h2>Herzlich Willkommen!</h2>
    <p :class="['pw-view__copy', { 'error-text': error }]" v-if="!isAppStarted">
      {{ content }}
    </p>
    <form @submit.prevent="handleSubmit">
      <div class="password-input">
        <input
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          required
          name="password"
          class="password-input__field"
        />
        <span class="password-input__toggle" @click="togglePasswordVisibility">
          <svg v-if="showPassword" class="password-input__eye-icon">
            <path :d="mdiEye"/>
          </svg>
          <svg v-else class="password-input__eye-icon">
            <path :d="mdiEyeOff"/>
          </svg>
        </span>
      </div>
      <div class="remember-me">
        <input type="checkbox" id="rememberLocal" v-model="rememberLocal" @change="updateCheckboxes('local')">
        <label for="rememberLocal">automatischer Login bei zukünftigen Besuchen</label>
      </div>
      <button type="submit" class="password-input__submit">eintreten</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { useStore } from 'vuex'

const password = ref('')
const showPassword = ref(false)
const content = ref('Diese Seite dient der Ergänzung meiner Bewerbung. Sie finden hier nähere Informationen zu mir und meinem Lebenslauf. Bitte geben Sie das Passwort ein, das ich Ihnen zugesendet habe:')
const error = ref(false)
const rememberSession = ref(false)
const rememberLocal = ref(true)

const store = useStore()

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

const updateCheckboxes = (sessionType) => {
  if (rememberLocal.value !== true) {
    rememberSession.value = !rememberSession.value
  }
  rememberLocal.value = sessionType === 'local' && rememberLocal.value
}

const handleSubmit = async () => {
  error.value = false
  const isLoggedIn = await store.dispatch('auth/sendLoginRequest', {
    password: password.value,
    rememberMe: rememberSession.value ? 'session' : (rememberLocal.value ? 'local' : '')
  })
  if (!isLoggedIn) {
    content.value = 'Das eingegebene Passwort existiert nicht oder wurde deaktiviert.'
    error.value = true
    await store.dispatch('auth/updateSession', { isAppStarted: false })
  }
}
</script>
<style scoped lang="scss">
.pw-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: lighter;
  word-break: normal;

  &__copy {
    min-height: 4.5rem;
  }
}

.password-input {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__field {
    padding: 0.5rem;
    background-color: $font-color;
    border-radius: 0.25rem;
  }

  &__toggle {
    cursor: pointer;
  }

  &__eye-icon {
    width: 24px;
    height: 24px;
    fill: $font-color;
  }

  &__submit {
    background-color: $font-color;
    color: $background;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
}

.remember-me {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.error-text {
  color: #ff7f11;
}

h2 {
  @include font-style('yanone', 'bolder', 'h2');
}

</style>
