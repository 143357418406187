<template>
  <div
    class="overlay"
    :style="backgroundStyle"
  ></div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  opacity: {
    type: String,
    required: false,
    default: '0.5'
  }
})

const backgroundStyle = {
  'background-color': 'rgba(0, 0, 0,' + props.opacity + ')'
}

</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>
