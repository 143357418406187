<template>
  <div class="text-section">
    <h2 v-if="props.textContent.headline" class="text-section__headline" v-html="props.textContent.headline"></h2>
    <span class="text-section__copy" v-html="props.textContent.content"></span>
    <h2 v-if="props.textContent.headline2" class="text-section__headline" v-html="props.textContent.headline2"></h2>
    <span v-if="props.textContent.content2" class="text-section__copy" v-html="props.textContent.content2"></span>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  textContent: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.text-section {
  font-weight: lighter;
  word-break: normal;

  &__headline {
    margin: 0;
    word-break: normal;
    @include font-style('yanone', 'normal', 'h2');
  }

  &__copy {
    margin-top: 10px;
  }

  a {
    font-weight: bolder;
    text-decoration: none;

    &:visited {
      color: $font-color;
    }

    &:hover,
    &:active {
      color: $font-color;
      font-weight: bolder;
    }
  }
}

.error-text {
  color: #ff7f11;
  text-align: center;
}
</style>
