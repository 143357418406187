<template>
  <div class="404">
    <CardComponent :text-content="text"/>
  </div>
</template>

<script setup>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'

defineComponent({
  name: '404View'
})
const text = {
  headline: 'Seite existiert nicht',
  content: '<p>Der eingegebene Pfad existiert nicht. </p>'
}
</script>
