<template>
  <div>
    <MainLayout :text-content="skillTexts">
      <template #left>
        <SkillTemplate :items="skills"/>
      </template>
    </MainLayout>
  </div>
</template>
<script setup>
import { environment, languages, softskills } from '@/assets/tableContents/skills.js'
import { languagesText, softSkillsText, technologiesText } from '@/assets/tableContents/skillTexts.js'
import SkillTemplate from '../components/SkillTemplate.vue'
// import { useStore, ref } from 'vuex'
import { defineComponent, defineProps, onMounted, ref, watch } from 'vue'
import MainLayout from '@/components/MainLayout.vue'
import { skillLogos } from '@/assets/tableContents/skillLogos'

const props = defineProps({
  topic: {
    type: String,
    required: true
  }
})

defineComponent({
  name: 'SkillView'
})

watch(() => props.topic, () => {
  setTopic()
  // Perform any additional updates or fetch data
})

/*   const store = useStore()
const closeMouth = () => { store.commit('avatar/setClosed') }
const setTooltipText = store.dispatch('avatar/setTooltipText')

const isActive = ref(false)
const activeComponent = ref('')
*/

const skills = ref([])
const skillTexts = ref({})

onMounted(() => {
  setTopic()
})

const setTopic = () => {
  switch (props.topic) {
    case 'Sprachkenntnisse':
      skills.value = languages
      skillTexts.value = languagesText
      break
    case 'Kenntnisse':
      skills.value = skillLogos
      skillTexts.value = technologiesText
      break
    case 'verwendete Technologien':
      skills.value = environment
      skillTexts.value = technologiesText
      break
    case 'Softskills':
      skills.value = softskills
      skillTexts.value = softSkillsText
      break
  }
}

</script>
