<template>
  <nav class="header-nav">
    <router-link
      class="nav-link"
      :to="{ name: 'HomeView' }"
      @click="handleClick()"
      exactActiveClass="nav-link--active"
    >
      Start
    </router-link>

    <router-link
      class="nav-link"
      :to="{ name: 'bio' }"
      @click="handleClick()"
      exactActiveClass="nav-link--active"
    >
      Lebenslauf
    </router-link>

    <router-link
      class="nav-link"
      :to="{ name: 'downloads' }"
      @click="handleClick()"
      exactActiveClass="nav-link--active"
    >
      Zeugnisse
    </router-link>

    <router-link
      class="nav-link"
      :to="{ name: 'contact' }"
      @click="handleClick()"
      exactActiveClass="nav-link--active"
    >
      Kontakt
    </router-link>
  </nav>
</template>

<script setup>
import { useStore } from 'vuex'

const store = useStore()
const closeSidebar = () => {
  store.dispatch('sidebar/setClosed')
}
const closeMouth = () => {
  store.dispatch('avatar/setAvatarClosed')
}
const handleClick = () => {
  closeMouth()
  closeSidebar()
}
</script>
<style lang="scss" scoped>

.header-nav {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.nav-link {
  color: $font-color;
  transition: 0.3s;
  z-index: 4;
  @include font-style('titillium', 'normal', 'normal');

  &--active, &:hover {
    font-weight: bolder;
  }
}

button {
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

</style>
