<template>
  <div class="text-section">
    <h2 class="text-section__headline">Kontakt</h2>

    <div class="contact-info">
      <div class="contact-info__left">
        Sie können mich per
        <a :href="'mailto:' + email + '?subject=Kontakt via Homepage'">E-Mail</a>
        <br/>
        <a href="tel:017697668901">Telefon: 017697668901</a>
        <br/>
        oder via
        <a href="https://www.xing.com/profile/TobiasFinn_ElLebeidy" target="_blank">
          <img src="@/assets/xing_btn.gif" alt="Xing" class="xing-img"/>
        </a>
        erreichen.
      </div>

      <div class="contact-info__right">
        <p class="h3">Anschrift</p>
        <p>
          Tobias-Finn El Lebeidy <br/>
          Schmalkaldener Str. 16 <br/>
          22761 Hamburg
        </p>
      </div>
    </div>

    <CardComponent :text-content="text"/>
  </div>
</template>

<script setup>
import { defineComponent, ref } from 'vue'
import CardComponent from '@/components/CardComponent.vue'

defineComponent({
  name: 'ContactView'
})

const emailCharsArray = [105, 110, 102, 111, 64, 116, 101, 108, 108, 102, 105, 110, 110, 46, 100, 101]
const email = ref(emailCharsArray.map(code => String.fromCharCode(code)).join(''))

const text = {
  headline: 'Disclaimer - rechtliche Hinweise',
  content: '<p>Diese Seite dient der Vervollständigung meiner Bewerbung. Ich biete hier keine Dienstleistungen gegen Entgelt an. <br> Diese Website enthält Verknüpfungen zu Websites Dritter ("externeLinks"). Diese Websites unterliegen der Haftung der jeweiligenBetreiber. Der Anbieter hat bei der erstmaligen Verknüpfung derexternen Links die fremden Inhalte daraufhin überprüft, ob etwaigeRechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstößeersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelleund zukünftige Gestaltung und auf die Inhalte der verknüpftenSeiten. Das Setzen von externen Links bedeutet nicht, dass sich derAnbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigenmacht. Eine ständige Kontrolle der externen Links ist für denAnbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.Bei Kenntnis von Rechtsverstößen werden jedoch derartige externeLinks unverzüglich gelöscht.<a href="#src">*</a> </p> <p class="footnote">* Quelle:<a id="src" class="disclaimer__link" href="https://www.juraforum.de/disclaimer_muster/" target="_blank"> Disclaimer-Muster von JuraForum.de</a></p>'
}
</script>

<style lang="scss" scoped>
.text-section {
  display: flex;
  flex-direction: column;
  font-weight: lighter;
  word-break: normal;
  gap: 1rem;

  &__headline {
    margin: 0;
    @include font-style('yanone', 'normal', 'h2');
    word-break: normal;
  }

  .contact-info {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    &__left,
    &__right {
      flex: 1;
    }

    .h3 {
      margin-top: 0;
      font-weight: bolder;
    }

    p {
      margin-top: 5px;
    }

    a {
      font-weight: bolder;
      text-decoration: none;

      &:visited {
        color: $font-color;
      }

      &:hover {
        text-decoration: underline;
      }

      .xing-img {
        vertical-align: middle;
        height: 1.1rem;
        margin: 0 0.5rem;
      }
    }
  }
}

:deep(.footnote) {
  margin-top: 1rem;
  font-size: smaller;
}
</style>
