<template>
  <div ref="content" class="tooltip">
    <div v-html="tooltipText"></div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const tooltipText = computed(() => store.state.avatar.tooltipText)
</script>

<style scoped lang="scss">
.tooltip {
  position: absolute;
  right: 80px;
  bottom: 35px;
  min-height: 50px;
  min-width: 200px;
  padding: 20px 15px;
  text-align: center;
  background-color: $font-color;
  color: #000000;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: lighter;

  :deep(a, a:visited, a:hover) {
    color: $header;
  }

  :deep(a[href$='.pdf']:before) {
    content: '';
    background: transparent url('@/assets/icons/file-pdf-outline_dark.png') left center no-repeat;
    padding: 12px;
    margin-right: 3px;
    vertical-align: 5%;
  }

  :deep(a[target="_blank"]:after) {
    content: url('@/assets/icons/open-in-new_dark.png');
    vertical-align: -7%;
    margin-left: 1px;
  }

  &::after {
    content: "";
    position: absolute;
    right: -20px;
    bottom: -32px;
    height: 80px;
    width: 20px;
    border-bottom: 15px solid $font-color;
    border-radius: 0 0 20px 0;
    transform: rotate(65deg);
  }

  :deep(ul) {
    margin-left: 10px;
    margin-top: 0;
    list-style: outside;
  }

  :deep(.tooltip__contact-area) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  :deep(.tooltip__contact-area--icon-wrapper) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 1rem;
    margin: 0.1rem 0;
  }

  :deep(.contact-icon-link) {
    height: 2rem;
    width: 2rem;

    &:hover {
      animation: grow 1s ease-in-out infinite;
      -webkit-animation: grow 1s ease-in-out infinite;
    }
  }
}

@keyframes grow {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
</style>
