export default {
  namespaced: true,
  state () {
    return {
      isOpen: false
    }
  },
  actions: {
    toggle ({ state }) {
      state.isOpen = !state.isOpen
    },
    setOpen ({ state }) {
      state.isOpen = true
    },
    setClosed ({ state }) {
      state.isOpen = false
    }
  }
}
