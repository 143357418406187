export default {
  namespaced: true,
  state () {
    return {
      isAppStarted: false
    }
  },
  actions: {
    async checkSession ({
      dispatch,
      state
    }) {
      const password = sessionStorage.getItem('loginData') || localStorage.getItem('loginData') || null
      const rememberMe = sessionStorage.getItem('loginData') ? 'session' : localStorage.getItem('loginData') ? 'local' : ''

      if (!password) {
        state.isAppStarted = false
      } else {
        await dispatch('sendLoginRequest', {
          password,
          rememberMe
        })
      }
    },

    updateSession ({ state }, {
      isAppStarted,
      password = null,
      setAutoLogin = ''
    }) {
      if (isAppStarted && password && setAutoLogin) {
        if (setAutoLogin === 'session') {
          sessionStorage.setItem('loginData', password)
        } else if (setAutoLogin === 'local') {
          localStorage.setItem('loginData', password)
        }
      } else {
        sessionStorage.removeItem('loginData')
        localStorage.removeItem('loginData')
      }

      state.isAppStarted = !!isAppStarted
    },

    async sendLoginRequest ({ dispatch }, {
      password,
      rememberMe = ''
    }) {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password })
      })

      if (response.ok) {
        dispatch('updateSession', {
          isAppStarted: true,
          password,
          setAutoLogin: rememberMe
        })
        return true
      } else {
        await dispatch('updateSession', { isAppStarted: false })
        return false
      }
    }
  }
}
