<template>
  <div class="bio-view">
    <div class="bio-view__button-wrapper">
      <a href="@/assets/pdfs/ellebeidy_CV.pdf" download class="bio-view__download-link">
        tabellarischer Lebenslauf als PDF
      </a>
    </div>

    <nav class="bio-view__nav-bar">
      <button :class="{ 'bio-view__nav-bar__button--active': selectedTab === 'experience' }"
              @click="selectedTab = 'experience'">
        praktische Erfahrung
      </button>
      <button :class="{ 'bio-view__nav-bar__button--active': selectedTab === 'education' }"
              @click="selectedTab = 'education'">
        Ausbildung
      </button>
    </nav>

    <TableComponent :array="filteredData"/>
  </div>
</template>

<script setup>
import { computed, defineComponent, ref } from 'vue'
import TableComponent from '@/components/TableComponent.vue'
import { education, experience } from '@/assets/tableContents/cv_data.js'

defineComponent({
  name: 'BioView'
})
const selectedTab = ref('experience')

const filteredData = computed(() => (selectedTab.value === 'experience' ? experience : education))
</script>

<style lang="scss" scoped>
.bio-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &__download-link {
    padding: 15px;
    border: 1px solid white;
    border-radius: 5px;

    &:hover {
      animation: grow 1s ease-in-out infinite;
      -webkit-animation: grow 1s ease-in-out infinite;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__nav-bar {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    &__button {
      padding: 0.5rem 0;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      color: $font-color;

      &--active {
        border-bottom: 1px solid $font-color;
        font-weight: bolder;
      }
    }
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
