<template>
  <div>
    <SkillBox :items="props.items"/>
  </div>
</template>
<script setup>

import { useStore } from 'vuex'
import { defineProps, onMounted } from 'vue'
import SkillBox from '@/components/SkillBox.vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => []
  }
})
const store = useStore()

const setTooltipText = (text) => {
  store.dispatch('avatar/setTooltipText', text)
}

onMounted(() => {
  setTooltipText('<p>Wenn Sie Hintergründe zu einer Kenntnis oder Fähigkeit erfahren möchten, klicken Sie sie an.</p>')
})

</script>
